import { Output } from '..';

/**
 * Validates the selection of extras for both single and split products.
 * @returns A boolean value indicating whether the extras selection is valid.
 */
export const validateExtrasSelection = (output: Output | null, outputSplit: Output | null) => {
	const leftCheck = output?._data.extras.every((data) => {
		const min_choose = data.min_choose ?? 0;
		const max_choose = data.max_choose ?? 0;

		const currentExtra = output.extras.find((extraGroup) => data._id === extraGroup._id);

		const quantity = currentExtra?.items.length;

		if (quantity != null) {
			if (quantity >= min_choose && (quantity <= max_choose || max_choose === 0)) {
				return true;
			} else {
				return false;
			}
		}
		return false;
	});

	const rightCheck = outputSplit
		? outputSplit._data.extras.every((data) => {
				const min_choose = data.min_choose ?? 0;
				const max_choose = data.max_choose ?? 0;

				const currentExtra = outputSplit.extras.find(
					(extraGroup) => data._id === extraGroup._id,
				);

				const quantity = currentExtra?.items.length;

				if (quantity != null) {
					if (quantity >= min_choose && (quantity <= max_choose || max_choose === 0)) {
						return true;
					} else {
						return false;
					}
				}
				return false;
			})
		: true;

	return leftCheck && rightCheck;
};
