import { useBranchByID } from './swr/useBranchByID';
import { useBranches } from './swr/useBranches';
import { useCategories } from './swr/useCategories';
import { useCategoryByID } from './swr/useCategoryByID';
import { useCoupon } from './swr/useCoupon';
import { useDealByID } from './swr/useDealByID';
import { useDealItems } from './swr/useDealItems';
import { useDeals } from './swr/useDeals';
import { useDealsByCategory } from './swr/useDealsByCategory';
import { useDealsByCategorySlug } from './swr/useDealsByCategorySlug';
import { useGiftcards } from './swr/useGiftcards';
import { useOrderByID } from './swr/useOrderByID';
import { useOrders } from './swr/useOrders';
import { useOrdersActive } from './swr/useOrdersActive';
import { useOrdersHistory } from './swr/useOrdersHistory';
import { usePaymentMethods } from './swr/usePaymentMethods';
import { useProductByID } from './swr/useProductByID';
import { useProducts } from './swr/useProducts';
import { useProductsByCategory } from './swr/useProductsByCategory';
import { useProductsByCategorySlug } from './swr/useProductsByCategorySlug';
import { useStore } from './swr/useStore';
import { useTags } from './swr/useTags';
import { useUser } from './swr/useUser';

export const data = {
	useBranchByID,
	useBranches,
	useCategories,
	useCategoryByID,
	useCoupon,
	useDealByID,
	useDealItems,
	useDeals,
	useGiftcards,
	useDealsByCategory,
	useDealsByCategorySlug,
	useOrderByID,
	useOrders,
	useOrdersActive,
	useOrdersHistory,
	usePaymentMethods,
	useProductByID,
	useProducts,
	useProductsByCategory,
	useProductsByCategorySlug,
	useStore,
	useUser,
	useTags,
};
