import { getBranchByID } from './getBranchByID';
import { getBranches } from './getBranches';
import { getCategories } from './getCategories';
import { getCategoryByID } from './getCategoryByID';
import { getDealByID } from './getDealByID';
import { getDeals } from './getDeals';
import { getGiftcards } from './getGiftcards';
import { getMenuData } from './getMenuData';
import { getOrderByID } from './getOrderByID';
import { getOrders } from './getOrders';
import { getPaymentMethods } from './getPaymentMethods';
import { getProductByID } from './getProductByID';
import { getProducts } from './getProducts';
import { getStore } from './getStore';
import { getTags } from './getTags';
import { getUser } from './getUser';

export const fetchers = {
	getBranchByID,
	getOrders,
	getBranches,
	getCategories,
	getCategoryByID,
	getDealByID,
	getDeals,
	getMenuData,
	getPaymentMethods,
	getProductByID,
	getProducts,
	getStore,
	getOrderByID,
	getUser,
	getGiftcards,
	getTags,
};
