import { add, format, isAfter, isBefore, parse, sub } from 'date-fns';
import { toZonedTime } from 'date-fns-tz';

import { Branch } from '..';

type BranchOpeningDataType = {
	isOpen: boolean;
	time_start: null | string;
	time_end: null | string;
	full_day: boolean;
};

/** TODO
 * 1. implement dynamic timezone based on user settings
 * 2. implement day off from settings
 */
export const isBranchOpen = (
	schedule: Branch['business_hours']['schedule'],
	now = toZonedTime(new Date().toISOString(), 'Iceland'),
): BranchOpeningDataType => {
	const dayString = format(now, 'iiii').toLowerCase();
	const yesterdayString = format(sub(now, { days: 1 }), 'iiii').toLowerCase();
	const tomorrowString = format(add(now, { days: 1 }), 'iiii').toLowerCase();
	const daySchedule = schedule.find((item) => item.active && item.day === dayString);
	const yesterdaySchedule = schedule.find((item) => item.active && item.day === yesterdayString);
	const tomorrowSchedule = schedule.find((item) => item.active && item.day === tomorrowString);
	const returnData: BranchOpeningDataType = {
		isOpen: false,
		time_start: null,
		time_end: null,
		full_day: false,
	};

	if (!daySchedule) {
		return returnData;
	}

	const openTimes = daySchedule.times.find(
		({ time_start, time_end }) =>
			time_start !== null &&
			time_end !== null &&
			isAfter(now, parse(time_start, 'HH:mm', now)) &&
			isBefore(now, parse(time_end, 'HH:mm', now)),
	);
	const isOpen = daySchedule.full_day || openTimes !== undefined;

	const todayTimes = [...(daySchedule?.times || [])].sort(sortByTime);
	const yesterdayTimes = [...(yesterdaySchedule?.times || [])].sort(sortByTime);
	const tomorrowTimes = [...(tomorrowSchedule?.times || [])].sort(sortByTime);

	if (
		openTimes !== undefined &&
		openTimes.time_start === '00:00' &&
		yesterdayTimes.length > 0 &&
		yesterdayTimes[yesterdayTimes.length - 1].time_end === '23:59'
	) {
		returnData.time_start = yesterdayTimes[yesterdayTimes.length - 1].time_start;
	} else if (openTimes !== undefined && openTimes.time_start !== '00:00') {
		returnData.time_start = openTimes.time_start;
	} else {
		const filteredTimes = daySchedule.times.filter(
			(item) => item.time_end === null || isBefore(now, parse(item.time_end, 'HH:mm', now)),
		);
		if (filteredTimes.length > 0) {
			returnData.time_start = filteredTimes[0].time_start;
		} else {
			returnData.time_start = todayTimes?.[todayTimes.length - 1]?.time_start;
		}
	}

	if (
		openTimes !== undefined &&
		openTimes.time_end === '23:59' &&
		tomorrowTimes.length > 0 &&
		tomorrowTimes[0].time_start === '00:00'
	) {
		returnData.time_end = tomorrowTimes[0].time_end;
	} else if (openTimes !== undefined && openTimes.time_end !== '23:59') {
		returnData.time_end = openTimes.time_end;
	} else {
		const filteredTimes = daySchedule.times.filter(
			(item) => item.time_end === null || isBefore(now, parse(item.time_end, 'HH:mm', now)),
		);
		if (filteredTimes.length > 0 && filteredTimes[0].time_end !== '23:59') {
			returnData.time_end = filteredTimes[0].time_end;
		} else if (filteredTimes.length > 0 && filteredTimes[0].time_end === '23:59') {
			returnData.time_end = tomorrowTimes[0].time_end;
		} else {
			returnData.time_end = todayTimes?.[todayTimes.length - 1]?.time_end;
		}
	}

	returnData.full_day = daySchedule.full_day;

	if (!isOpen) {
		return returnData;
	}

	returnData.isOpen = true;

	return returnData;
};

const sortByTime = (
	a: Branch['business_hours']['schedule'][0]['times'][0],
	b: Branch['business_hours']['schedule'][0]['times'][0],
) => {
	if (a.time_start === null && b.time_start === null) {
		return 0;
	} else if (a.time_start === null) {
		return 1;
	} else if (b.time_start === null) {
		return -1;
	}
	return a.time_start.localeCompare(b.time_start);
};
