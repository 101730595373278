import { CartItemProductSingle, CartItemProductSplit } from '../../..';
import { OrderItemProductSingle, convertProductSingle } from './convertProductSingle';

type OrderItemProductSplit = {
	quantity: number;
	item_note: string;
	type: 'split_product';
	split_items: [OrderItemProductSingle, OrderItemProductSingle];
};

export const convertProductSplit = (
	cartItemProductSplit: CartItemProductSplit,
): OrderItemProductSplit => {
	const { output, outputSplit, quantity } = cartItemProductSplit;

	const leftOutput: CartItemProductSingle = {
		output: output,
		outputSplit: null,
		outputDeal: null,
		quantity: 1,
		note: '',
	};

	const rightOutput: CartItemProductSingle = {
		output: outputSplit,
		outputSplit: null,
		outputDeal: null,
		quantity: 1,
		note: '',
	};

	const split_items: [OrderItemProductSingle, OrderItemProductSingle] = [
		convertProductSingle(leftOutput),
		convertProductSingle(rightOutput),
	];

	return {
		quantity: quantity,
		item_note: '',
		type: 'split_product',
		split_items,
	};
};
