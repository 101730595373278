import { Output } from '..';

/**
 * Retrieves the text representation of extras in the output.
 *
 * @param {Output} output - The output data.
 * @returns {string[]} The array of extra texts.
 */
export const getExtrasText = (output: Output): string[] => {
	if (!output) {
		return [];
	}

	const added = (() => {
		return output.extras.map((outputExtra) => {
			const dataExtra = output._data?.extras.find(
				(dataExtra) => dataExtra._id === outputExtra._id,
			);
			return outputExtra.items.map((item) => {
				const dataExtraItem = dataExtra?.items.find(
					(dataExtraItem) => dataExtraItem._id === item._id,
				);

				if (!dataExtraItem) {
					throw new Error('Invalid, data extra item not found');
				}

				if (dataExtraItem.include) {
					if (item.quantity > 2) {
						return `+ ${dataExtraItem.name} (${item.quantity - 1})`;
					} else if (item.quantity === 2) {
						return `+ ${dataExtraItem.name}`;
					} else {
						return '';
					}
				} else {
					if (item.quantity === 1) {
						return `+ ${dataExtraItem.name}`;
					} else {
						return `+ ${dataExtraItem.name} (${item.quantity})`;
					}
				}
			});
		});
	})();

	const removed =
		output._data?.extras?.map((dataExtra) => {
			const outputExtra = output.extras.find((ref) => ref._id === dataExtra._id);
			return dataExtra.items
				.filter((item) => item.include)
				.map((item) => {
					const outputExtraItem = outputExtra?.items.find(
						(outputExtraItem) => outputExtraItem._id === item._id,
					);
					return outputExtraItem ? '' : '- ' + item.name;
				})
				.filter((item) => item);
		}) || [];

	return [...removed, ...added].flat().filter((extra) => extra);
};
