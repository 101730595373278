import { Product, isProductValid, upsellAPI } from '../..';

/**
 * Function to fetch products by ID from Upsell API
 * @param {string} productID - The ID of the product to fetch
 * @param {Object} [config] - Optional configuration
 * @param {boolean} [config.onlyValidProduct=false] - Whether to only return valid products
 * @param {AbortSignal} [signal] - Optional signal to abort the request
 * @returns {Promise<Product>} Product data
 */
export const getProductByID = (
	productID: string,
	config: { onlyValidProduct?: boolean } = {},
	signal?: AbortSignal,
) => {
	const { onlyValidProduct = false } = config;
	const endpoint = `/products/${productID}`;

	return upsellAPI()
		.get<{ data: Product }>(endpoint, { signal })
		.then((res) => (onlyValidProduct ? isProductValid(res.data.data) : res.data.data));
};
