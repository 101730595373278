import { Tag, upsellAPI } from '../..';

/**
 * Function to fetch all product tags from Upsell API
 * @param {AbortSignal} signal? - Optional signal to abort the request
 * @returns {Tag[]} Array of Tag data
 */
export const getTags = (signal?: AbortSignal) => {
	const endpoint = `/tags`;

	return upsellAPI()
		.get<{
			data: Tag[];
		}>(endpoint, { signal })
		.then((res) => res.data.data);
};
