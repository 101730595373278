import useSWR from 'swr';

import upsell from '../..';

/**
 * SWR hook to fetch all giftcards
 * @returns returning data, isLoading, isValidating, mutate, and error
 */
export const useCoupon = () => {
	const couponCode = typeof window !== 'undefined' ? window.couponCode : '';
	const swrResponse = useSWR(couponCode ? 'coupon' : undefined, () =>
		upsell.payment.coupon.get(couponCode),
	);

	return {
		...swrResponse,
		isInvalid: couponCode && typeof swrResponse.data === 'undefined',
	};
};
