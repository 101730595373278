import upsell, { joinText } from '../../..';
import { handleOrderTotal } from './handler/handleOrderTotal';
import { handleProductCategory } from './handler/handleProductCategory';

export type ReturnType = {
	discountAmount: number;
	couponAmount: number;
	afterDiscountAndCoupon: number;
	couponError: string;
	couponSuccess: string;
};

/**
 * Calculates the discount and coupon amounts for items in the cart and returns the adjusted totals.
 *
 * @returns {Object} An object containing:
 *   - `couponAmount` {number}: The total discount amount from the coupon.
 *   - `discountAmount` {number}: The total discount amount before the coupon.
 *   - `afterDiscountAndCoupon` {number}: The final price after discounts and coupon.
 *   - `couponError` {string}: Any error message related to coupon application.
 *
 * This function applies coupons and discounts to cart items, considering branch restrictions, coupon types,
 * minimum spend requirements, and excluded categories using hooks from the `upsell` library.
 */
export const useDiscountAndCoupon = (): ReturnType => {
	const { data: couponData, isInvalid } = upsell.data.useCoupon();
	const { data: branchesData } = upsell.data.useBranches();
	const { data: categoriesData } = upsell.data.useCategories();
	const { data: productsData } = upsell.data.useProducts();
	const { branchID } = upsell.provider.checkout.useContext().shipping;

	const { items } = upsell.cart.useItems();
	const cartPrice = upsell.cart.usePrice();
	const cartDiscount = cartPrice.price.previous - cartPrice.price.final;

	if (!branchesData || !categoriesData || !productsData) {
		return {
			discountAmount: cartDiscount,
			couponAmount: 0,
			afterDiscountAndCoupon: cartPrice.price.final,
			couponError: ``,
			couponSuccess: ``,
		};
	}

	// 	COUPON VALIDATION
	if (!couponData) {
		if (isInvalid) {
			return {
				discountAmount: cartDiscount,
				couponAmount: 0,
				afterDiscountAndCoupon: cartPrice.price.final,
				couponError: `Coupon code is invalid`,
				couponSuccess: ``,
			};
		} else {
			return {
				discountAmount: cartDiscount,
				couponAmount: 0,
				afterDiscountAndCoupon: cartPrice.price.final,
				couponError: ``,
				couponSuccess: ``,
			};
		}
	}

	// BRANCH VALIDATION
	if (!couponData.branches.includes(branchID)) {
		const availableBranches = couponData.branches.map((availableBranchID) => {
			const branchData = branchesData.find((branch) => branch._id === availableBranchID);
			return branchData?.location_name || '';
		});

		return {
			discountAmount: cartDiscount,
			couponAmount: 0,
			afterDiscountAndCoupon: cartPrice.price.final,
			couponError: `
				Coupon is not available in this branch, 
				select branch ${joinText.withOr(availableBranches)}
			`,
			couponSuccess: ``,
		};
	}

	// SWITCH BASED ON COUPON TYPE
	const couponType = couponData.coupon_type.type;

	if (couponType === 'order_total') {
		return handleOrderTotal({
			couponData,
			items,
			categoriesData,
			cartPrice,
			cartDiscount,
		});
	} else if (couponType === 'product_category') {
		return handleProductCategory({
			couponData,
			items,
			productsData,
			categoriesData,
			cartPrice,
			cartDiscount,
		});
	} else if (couponType === 'shipping') {
		throw new Error('Coupon type shipping is under development');
	} else if (couponType === 'buyx_gety') {
		throw new Error('Coupon type buyx_gety is not supported yet');
	} else {
		throw new Error('other coupon type is not supported yet');
	}
};
