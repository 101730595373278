import upsell, { useDiscountAndCoupon } from '../..';

/**
 * Hook to compute and return a summary of the prices in the cart.
 *
 * Additionally, it handles the payment method selection to `cash` or `giftcard` based on the remaining amount
 * after applying giftcard or coupon.
 *
 * Priority scale:
 * - select biggest amount between discount and coupon for product category coupon
 * - discount > coupon > giftcard
 *
 * @returns {Object} An object containing the computed price summary.
 */
export const usePriceSummary = () => {
	const checkoutState = upsell.provider.checkout.useContext();
	const cartPrice = upsell.cart.usePrice();
	const { isLoading: isCartLoading } = upsell.cart.useItems();
	const { data: couponData } = upsell.data.useCoupon();
	const { authStatus } = upsell.data.useUser();
	const { data: giftcardsData } = upsell.data.useGiftcards();
	const { data: branchData } = upsell.data.useBranchByID(checkoutState.shipping.branchID);

	// ORIGINAL
	const original = cartPrice.price.previous;

	// TAXATION
	const taxRate = branchData?.vat_rate || 11;
	const taxIncluded = Math.round((original * taxRate) / 100);
	const beforeTax = Math.round((original * (100 - taxRate)) / 100);

	// COUPON AND DISCOUNT
	const { discountAmount, couponAmount, afterDiscountAndCoupon, couponError, couponSuccess } =
		useDiscountAndCoupon();

	// Determine if coupon is applicable
	if (couponData && couponAmount > 0) {
		checkoutState.setCoupon(couponData.code);
	} else {
		checkoutState.resetCoupon();
	}

	// GIFTCARDS
	const giftcardsAmount = Math.min(
		giftcardsData
			?.filter((giftcard) =>
				checkoutState.giftcards.includes(giftcard.individual_giftcard.code),
			)
			.map(
				(giftcard) =>
					giftcard.individual_giftcard.initial_value -
					giftcard.individual_giftcard.value_used,
			)
			.reduce((a, b) => a + b, 0) || 0,
		afterDiscountAndCoupon,
	);

	const afterGiftcards = Math.max(0, afterDiscountAndCoupon - giftcardsAmount);

	// FINAL
	const total = Math.max(0, afterGiftcards);

	// Determine the payment method based on the remaining amount
	if (afterDiscountAndCoupon === 0 && !isCartLoading) {
		checkoutState.setPayment(upsell.payment.initialState.cash);
	} else {
		if (checkoutState.shipping.method === 'delivery' || authStatus === 'guest') {
			if (afterGiftcards === 0) {
				checkoutState.setPayment(upsell.payment.initialState.giftcard);
			} else if (
				checkoutState.payment?.payment_method === 'giftcard' ||
				checkoutState.payment?.payment_method === 'cash'
			) {
				checkoutState.resetPayment();
			}
		} else {
			if (afterGiftcards === 0) {
				checkoutState.setPayment(upsell.payment.initialState.giftcard);
			} else if (checkoutState.payment?.payment_method === 'giftcard') {
				checkoutState.resetPayment();
			}
		}
	}

	return {
		original,
		taxRate,
		taxIncluded,
		beforeTax,
		discountAmount,
		couponAmount,
		couponError,
		couponSuccess,
		giftcardsAmount,
		total,
	};
};
