'use client';

import { addMinutes, differenceInSeconds, format } from 'date-fns';
import { useEffect, useState } from 'react';

import { Order, calculateEstimationProgress } from '../..';

export const useOrderEstimation = (order: Order | undefined) => {
	const [currentTime, setCurrentTime] = useState<Date>(new Date());

	useEffect(() => {
		const interval = setInterval(() => {
			setCurrentTime(new Date());
		}, 1000);

		return () => clearInterval(interval);
	}, []);

	if (!order) {
		return {
			estimatedTime: '',
			estimatedTimeWith5MinutesMargin: '',
			estimatedDate: '',
			percentageElapsed: 0,
			timeRemaining: 0,
		};
	}

	const waitingTime = order.waiting_time;
	const orderTime = order.createdAt;

	const { percentageElapsed, endDate } = calculateEstimationProgress(
		orderTime,
		waitingTime,
		currentTime,
	);

	const estimatedTime = format(endDate, 'HH:mm');
	const estimatedTimeWith5MinutesMargin = format(addMinutes(endDate, 5), 'HH:mm');
	const estimatedDate = format(endDate, 'd MMM yyyy, HH:mm');

	// Calculate the time remaining in minutes
	const timeRemaining = differenceInSeconds(endDate, currentTime);

	return {
		estimatedTime,
		estimatedTimeWith5MinutesMargin,
		estimatedDate,
		percentageElapsed,
		timeRemaining,
	};
};
