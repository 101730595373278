import { useState } from 'react';
import { cache } from 'swr/_internal';
import useLocalStorage from 'use-local-storage';

import upsell, {
	PaymentMethod,
	cancelOrder,
	cartStorage,
	createOrder,
	saveGuestOrder,
} from '../..';
import { CheckoutContextType, Shipping, UseContext, initialValue } from './initialValue';

export const useContext: UseContext = () => {
	// PAYMENT STATES
	const [payment, setPayment] = useState(initialValue.payment);
	const [giftcards, setGiftcards] = useState(initialValue.giftcards);
	const [coupon, setCoupon] = useState(initialValue.coupon);
	const [paymentResult, setPaymentResult] = useState(initialValue.paymentResult);

	// OTHER STATES
	const [note, setNote] = useState(initialValue.note);
	const [schedule, setSchedule] = useState(initialValue.schedule);
	const [additionalCost, _setAdditionalCost] = useState(initialValue.additionalCost);

	const [shipping, setShipping] = useLocalStorage('@upsell-kit/shipping', initialValue.shipping, {
		serializer: (value) => JSON.stringify({ ...value, branchID: '', method: '' }),
	});

	// SHIPPING RELATED
	const changeShippingMethod: CheckoutContextType['changeShippingMethod'] = (shippingMethod) => {
		if (payment !== null) {
			const paymentMethods = cache.get('payment-methods')?.data as PaymentMethod[];

			const payment_type =
				paymentMethods?.find(
					(paymentMethod) => paymentMethod.payment_id === payment.payment_method,
				)?.payment_type || '';

			if (payment_type === 'custom') {
				resetPayment();
			}
		}

		setShipping((prevShipping) => ({
			...(prevShipping as Shipping),
			method: shippingMethod,
		}));
	};

	const changeBranch: CheckoutContextType['changeBranch'] = (branchID) => {
		setShipping((prevShipping) => ({
			...(prevShipping as Shipping),
			branchID,
		}));
	};

	const updateShippingData: CheckoutContextType['updateShippingData'] = (shippingData) => {
		if (shipping) {
			setShipping({
				...shipping,
				data: {
					...shipping.data,
					...shippingData,
				},
			});
		}
	};

	// GIFTCARD
	const applyGiftcard: CheckoutContextType['applyGiftcard'] = (giftcardCode) => {
		if (typeof giftcardCode === 'string') {
			if (giftcards.includes(giftcardCode)) {
				throw new Error(`Giftcard ${giftcardCode} already applied`);
			}

			setGiftcards((prev) => [...prev, giftcardCode]);
		} else {
			setGiftcards((prev) => Array.from(new Set([...prev, ...giftcardCode])));
		}
	};

	const removeGiftcard: CheckoutContextType['removeGiftcard'] = (giftcardCode) => {
		if (typeof giftcardCode === 'string') {
			setGiftcards((prev) => prev.filter((giftcard) => giftcard !== giftcardCode));
		} else {
			setGiftcards((prev) => prev.filter((giftcard) => !giftcardCode.includes(giftcard)));
		}
	};

	// CHECKOUT
	const checkout: CheckoutContextType['checkout'] = async ({
		payment: paymentOverride,
		callback,
	}) => {
		const paymentToUse = paymentOverride || payment;
		if (paymentToUse === null) {
			throw new Error('Please select a payment method');
		}

		const cartItems = cartStorage.get();
		if (!cartItems) {
			throw new Error('Failed to get cart items');
		}

		const order = await createOrder({
			payment: paymentToUse,
			giftcards,
			shipping,
			additionalCost,
			coupon,
			note,
			schedule,
			cartItems,
		});

		if (!order) {
			throw new Error('Failed to create order');
		}

		const getPaymentResult = () => {
			if (paymentToUse.payment_method === 'cash') {
				return upsell.payment.cash.pay(paymentToUse, order);
			} else if (paymentToUse.payment_method === 'giftcard') {
				return upsell.payment.giftcard.pay(paymentToUse, order);
			} else if (paymentToUse.payment_method === 'teya-rpg') {
				return upsell.payment.teya.pay(paymentToUse, order);
			} else if (paymentToUse.payment_method === 'apple-pay') {
				return upsell.payment.apple.pay(paymentToUse, order);
			} else {
				throw new Error('under development');
			}
		};

		const paymentResult = await getPaymentResult();
		if (!paymentResult) {
			cancelOrder(order._id);
			throw new Error('Failed to handle payment');
		}
		setPaymentResult(paymentResult);

		const isManualPayment =
			paymentToUse.payment_method === 'cash' || paymentToUse.payment_method === 'giftcard';

		const isTeyaTransactionSuccess =
			paymentToUse.payment_method &&
			['apple-pay', 'teya-rpg'].includes(paymentToUse.payment_method) &&
			'TransactionStatus' in paymentResult &&
			paymentResult.TransactionStatus === 'Accepted';

		if (isManualPayment || isTeyaTransactionSuccess) {
			saveGuestOrder(order._id);
			reset();

			if (callback) {
				callback(paymentResult, order);
			}
		}
	};

	const reset: CheckoutContextType['reset'] = () => {
		changeBranch('');
		setPaymentResult(initialValue.paymentResult);
		setPayment(initialValue.payment);
		setCoupon(initialValue.coupon);
		setGiftcards(initialValue.giftcards);
		setNote(initialValue.note);
		_setAdditionalCost(initialValue.additionalCost);

		upsell.cart.actions.reset();
		return;
	};

	const resetCoupon = () => setCoupon(initialValue.coupon);

	const resetPayment = () => setPayment(initialValue.payment);

	return {
		// STATE
		shipping,
		payment,
		giftcards,
		additionalCost,
		coupon,
		note,
		schedule,
		paymentResult,
		// ACTIONS
		changeShippingMethod,
		changeBranch,
		updateShippingData,
		setPayment,
		applyGiftcard,
		removeGiftcard,
		setCoupon,
		setNote,
		setSchedule,
		checkout,
		resetCoupon,
		resetPayment,
		reset,
	};
};
