import useSWR from 'swr';

import { fetchers } from '../..';

export interface Config {
	limit: string;
}

/**
 * SWR hook to fetch active orders
 * @returns returning data, isLoading, isValidating, mutate, and error
 * */
export const useOrdersHistory = (config?: Config) =>
	useSWR('orders-history', () =>
		fetchers.getOrders({
			start: new Date(new Date().setMonth(new Date().getMonth() - 1)).toUTCString(),
			end: new Date(Date.now() - 24 * 60 * 60 * 1000).toUTCString(),
			sort_by: 'order_number-desc',
			limit: '10',
			order_status:
				'order_received,processing,ready_to_ship,ready_to_pickup,on_delivery,delivered,completed,refunded,failed,paid,in_oven',
			...(config ? config : {}),
		}),
	);
