import { Product, convertVariations } from '..';

export const preselectVariables = (
	variationsData: Product['variations'],
	currentVariation?: Product['variations'][0] | null,
) => {
	if (variationsData.length === 0) {
		return [];
	}

	const defaultVariation =
		variationsData.find((variation) => variation.is_default_variation) ||
		variationsData.find((variation) => variation.active);

	if (defaultVariation) {
		if (!currentVariation) {
			return convertVariations.toVariables(defaultVariation);
		} else {
			const isMatch = variationsData.find(
				(variation) =>
					variation.variants.length === currentVariation.variants.length &&
					variation.variants.every((variant) => {
						const currentVariant = currentVariation.variants.find(
							(currentVariant) =>
								currentVariant.variable._id === variant.variable._id,
						);
						return currentVariant?.attribute._id === variant.attribute._id;
					}),
			)?.active;

			if (isMatch) {
				return convertVariations.toVariables(currentVariation);
			} else {
				return convertVariations.toVariables(defaultVariation);
			}
		}
	} else {
		throw new Error('Invalid product data, can not determine dafault variables');
	}
};
