import { Deal, DealItem, Output, OutputDeal, Price, Product, ProductExtended } from '../..';

export const initialValue: CustomizationContextType = {
	quantity: 1,
	output: null,
	outputSplit: null,
	outputDeal: null,
	note: '',
	sideFocus: 'left',
	activeOutput: null,
	inactiveOutput: null,
	init: (_product: Product | Deal | undefined, _params?: CustomizationParams) => {
		return;
	},
	split: () => {
		return;
	},
	unsplit: () => {
		return;
	},
	setNote: (_note: string) => {
		return;
	},
	setSideFocus: (_state: 'left' | 'right') => {
		return;
	},
	increaseQuantity: () => {
		return;
	},
	decreaseQuantity: () => {
		return;
	},
	updateVariable: (_variableId: string, _attributeId: string) => {
		return;
	},
	updateExtra: (
		_extraID: string,
		_itemID: string,
		_quantity: number,
		_isRadio: boolean | undefined,
	) => {
		return;
	},
	getDealPrice: (): Price => {
		return {
			base: {
				final: 0,
				previous: 0,
			},
			extra: {
				final: 0,
				previous: 0,
			},
			price: {
				final: 0,
				previous: 0,
			},
		};
	},
	getDealItemPrice: (): Price => {
		return {
			base: {
				final: 0,
				previous: 0,
			},
			extra: {
				final: 0,
				previous: 0,
			},
			price: {
				final: 0,
				previous: 0,
			},
		};
	},
	getProductPrice: (): Price => {
		return {
			base: {
				final: 0,
				previous: 0,
			},
			extra: {
				final: 0,
				previous: 0,
			},
			price: {
				final: 0,
				previous: 0,
			},
		};
	},
	addDealItem: (_dealItem: DealItem) => {
		return;
	},
	submit: (_params?: CustomizationParams, _callback?: () => void) => {
		return;
	},
	applyDealsWithPreselectedItem: (
		_deal: Deal,
		_menuID: string,
		_product: ProductExtended,
		_callback: (status: string) => void,
	) => {
		return;
	},
	reset: () => {
		return;
	},
};

export type CustomizationParams = {
	menuID?: string | null;
	edit?: string | null;
	preselect?: Output['variables'] | 'auto' | 'partial' | false;
};

export type CustomizationContextType = {
	// STATE
	quantity: number;
	output: Output | null;
	outputSplit: Output | null;
	outputDeal: OutputDeal | null;
	note: string;
	sideFocus: 'left' | 'right';
	activeOutput: Output | null;
	inactiveOutput: Output | null;
	// ACTIONS
	init: (product: Product | Deal | undefined, params?: CustomizationParams) => void;
	split: () => void;
	unsplit: () => void;
	setNote: (note: string) => void;
	setSideFocus: (state: 'left' | 'right') => void;
	increaseQuantity: () => void;
	decreaseQuantity: () => void;
	updateVariable: (variableId: string, attributeId: string) => void;
	updateExtra: (extraID: string, itemID: string, quantity: number, isRadio?: boolean) => void;
	getDealPrice: () => Price;
	getDealItemPrice: () => Price;
	getProductPrice: () => Price;
	submit: (params?: CustomizationParams, callback?: () => void) => void;
	addDealItem: (dealItem: DealItem) => void;
	applyDealsWithPreselectedItem: (
		deal: Deal,
		menuID: string,
		product: ProductExtended,
		callback: (status: string) => void,
	) => void;
	reset(): void;
};

export type UseContext = () => CustomizationContextType;
