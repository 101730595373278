import { errorLog } from '@monorepo/workers/logger';
import { cache } from 'swr/_internal';

import {
	Branch,
	CartItem,
	Order,
	PaymentState,
	User,
	convertCart,
	isBranchOpen,
	tokenStorage,
	upsellAPI,
} from '../..';
import { CheckoutContextType } from '../../provider/checkout/initialValue';

type CreateOrderParams = {
	coupon: CheckoutContextType['coupon'];
	note: CheckoutContextType['note'];
	schedule: CheckoutContextType['schedule'];
	payment: PaymentState;
	shipping: CheckoutContextType['shipping'];
	additionalCost: CheckoutContextType['additionalCost'];
	giftcards: string[];
	cartItems: CartItem[];
};

/**
 * Creates a new order based on the provided parameters.
 * @param {CreateOrderParams} params - The parameters for creating the order.
 * @param {PaymentState} params.payment - The payment state.
 * @param {CheckoutContextType['coupon']} params.coupon - The coupon used for the order.
 * @param {CheckoutContextType['note']} params.note - Any notes for the order.
 * @param {CheckoutContextType['shipping']} params.shipping - Shipping details for the order.
 * @param {CheckoutContextType['additionalCost']} params.additionalCost - Any additional costs for the order.
 * @param {CheckoutContextType['giftcard']} params.giftcard - The gift card used for the order.
 * @returns {Promise<Order>} A promise that resolves to the created order.
 * @throws {Error} If any required data is missing or the branch is not open.
 */
export const createOrder = async ({
	payment,
	coupon,
	note,
	schedule,
	shipping,
	additionalCost,
	giftcards,
	cartItems,
}: CreateOrderParams): Promise<Order> => {
	const branch = cache.get(`branch-by-id-${shipping.branchID}`)?.data as Branch;
	if (!branch) {
		throw new Error('Branch is required');
	}
	if (!isBranchOpen(branch?.business_hours?.schedule ?? []).isOpen) {
		throw new Error('The store you selected is not open');
	}

	const user = cache.get(`user`)?.data as User | undefined;
	const { guestToken } = tokenStorage.get();

	if (!user && !guestToken) {
		throw new Error('Authentication is required');
	}

	const payload = {
		customer_id: user?._id || '',
		order_note: note,
		order_method: shipping.method,
		payment_id: payment.payment_method === 'apple-pay' ? 'teya-rpg' : payment.payment_method,
		order_status: ['cash', 'giftcard'].includes(`${payment.payment_method}`)
			? 'processing'
			: 'waiting_payment',
		branch: shipping.method === 'pickup' ? branch?._id : shipping.branchID,
		currency: 'isk',
		shipping: {
			name: shipping.data.name,
			address: shipping.data.address,
			address_detail: shipping.data.address_detail,
			postcode: shipping.data.postcode,
			shipping_note: shipping.data.shipping_note,
		},
		billing: {
			name: user?.name || guestToken?.name,
			address: user?.customer_address || guestToken?.address,
			email: {
				email_verified: user?.email.email_verified || guestToken?.email.verified,
				email_address: user?.email.email_address || guestToken?.email.address,
			},
			phone: user?.phone.phone_number || guestToken?.phone,
		},
		additionalCost: additionalCost,
		items: convertCart.toOrder(cartItems),
		giftcard_code: giftcards,
		coupon_code: coupon,
		schedule_date: schedule || '',
	};

	// CREATE ORDER
	return upsellAPI()
		.post<{
			data: Order;
		}>('/orders', payload)
		.then((res) => res.data.data)
		.catch((err) => {
			errorLog({ error: err.message });

			throw new Error('Failed to order');
		});
};
